import * as api from '../api';
import { PURGE } from 'redux-persist';

const GET_FLEET_RESERVATIONS = 'reservations/GET_FLEET_RESERVATIONS';
const GET_DAILY_FLEET_RESERVATIONS = 'reservations/GET_DAILY_FLEET_RESERVATIONS';
const GET_MONTHLY_FLEET_RESERVATIONS = 'reservations/GET_MONTHLY_FLEET_RESERVATIONS';
const CANCEL_FLEET_RESERVATION = 'reservations/CANCEL_FLEET_RESERVATION';
const REFUND_FLEET_RESERVATION = 'reservations/REFUND_FLEET_RESERVATION';
const PARTIALLY_REFUND_FLEET_RESERVATION = 'reservations/PARTIALLY_REFUND_FLEET_RESERVATION';
const ACTIVATE_FLEET_RESERVATION = 'reservations/ACTIVATE_FLEET_RESERVATION';
const REASSIGN_VEHICLE_TO_RESERVATION = 'reservations/ACTIVATE_FLEET_RESERVATION';

// Change initialState to an object to hold separate reservations arrays
const initialState = {
  fleetReservations: [],
  dailyReservations: [],
  monthlyReservations: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_FLEET_RESERVATIONS:
      return { ...state, fleetReservations: action.data };
    case GET_DAILY_FLEET_RESERVATIONS:
      return { ...state, dailyReservations: action.data };
    case GET_MONTHLY_FLEET_RESERVATIONS:
      return { ...state, monthlyReservations: action.data };
    case CANCEL_FLEET_RESERVATION:
      return {
        ...state,
        fleetReservations: state.fleetReservations.filter((r) => r.id !== action.data),
        dailyReservations: state.dailyReservations.filter((r) => r.id !== action.data),
        monthlyReservations: state.monthlyReservations.filter((r) => r.id !== action.data),
      };
    case ACTIVATE_FLEET_RESERVATION:
      return state;
    case REFUND_FLEET_RESERVATION:
    case REASSIGN_VEHICLE_TO_RESERVATION:
    case PARTIALLY_REFUND_FLEET_RESERVATION:
      return {
        ...state,
        fleetReservations: state.fleetReservations.map((r) =>
          r.id === action.data.id ? { ...r, refunded: true } : r
        ),
        dailyReservations: state.dailyReservations.map((r) =>
          r.id === action.data.id ? { ...r, refunded: true } : r
        ),
        monthlyReservations: state.monthlyReservations.map((r) =>
          r.id === action.data.id ? { ...r, refunded: true } : r
        ),
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
}

export const activateFleetReservation = (jwt, reservation_id, params) => {
  return (dispatch) => {
    return api
      .activateFleetReservation(jwt, reservation_id, params)
      .then((data) => dispatch({ type: ACTIVATE_FLEET_RESERVATION, data: reservation_id }));
  };
};

export const getFleetReservations = (jwt, fleet_id) => {
    return (dispatch) => {
      return api
        .getFleetReservations(jwt, fleet_id)
        .then((data) => dispatch({ type: GET_FLEET_RESERVATIONS, data: data }));
    };
  };

export const getDayFleetReservations = (jwt, fleet_id, day) => {
  console.log('get day at state');
  return (dispatch) => {
    return api
      .getDayFleetReservations(jwt, fleet_id, day)
      .then((data) => {
        dispatch({ type: GET_DAILY_FLEET_RESERVATIONS, data: data });
      });
  };
};


export const getMonthlyFleetReservations = (jwt, fleet_id, month, year) => {
  return (dispatch) => {
    return api
      .getFleetReservations(jwt, fleet_id, month, year) // Ensure you call the correct API method
      .then((data) => {
        dispatch({ type: GET_MONTHLY_FLEET_RESERVATIONS, data: data });
      });
  };
};

export const cancelFleetReservation = (jwt, reservation_id, params) => {
  return (dispatch) => {
    return api
      .cancelFleetReservation(jwt, reservation_id, params)
      .then(() => dispatch({ type: CANCEL_FLEET_RESERVATION, data: reservation_id }));
  };
};

export const partiallyRefundFleetReservation = (jwt, reservationId, params) => {
  return async (dispatch) => {
    try {
      const data = await api.refundPartialReservation(jwt, reservationId, params);
      dispatch({ type: PARTIALLY_REFUND_FLEET_RESERVATION, data: params });
      return data;
    } catch (error) {
      console.error('Error in refunding:', error);
      throw error;
    }
  };
};

export const refundFleetReservation = (jwt, reservation_id, params) => {
  return async (dispatch) => {
    try {
      const data = await api.refundReservation(jwt, reservation_id, params);
      dispatch({ type: REFUND_FLEET_RESERVATION, data: { id: reservation_id } });
      return data;
    } catch (error) {
      console.error('Error in refunding:', error);
      throw error;
    }
  };
};


export const reassignVehicle = (jwt, reservation_id, params) => {
  return async (dispatch) => {
    try {
      const data = await api.reassignVehicleToReservation(jwt, reservation_id, params);
      dispatch({ type: REASSIGN_VEHICLE_TO_RESERVATION, data: { id: reservation_id } });
      return data;
    } catch (error) {
      console.error('Error in refunding:', error);
      throw error;
    }
  };
};






